import { Injectable } from '@angular/core';


export interface SpeechSynthesisVoice {
  voiceURI: string;
  name: string;
  lang: string;
  localService: boolean;
  default: boolean;
}
export interface SpeechSynthesis {
  paused: boolean;
  pending: boolean;
  speaking: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SpeechService {

  speechSynthesis: any;
  voices!: SpeechSynthesisVoice[];

  falarTexto(texto: string) {

    if ('speechSynthesis' in window) {
      this.speechSynthesis = window.speechSynthesis;
      this.voices = this.speechSynthesis.getVoices();
    }

    this.speechSynthesis = window.speechSynthesis;
    this.voices = this.speechSynthesis.getVoices();

    let utterance = new SpeechSynthesisUtterance(texto);
    utterance.voice = this.voices.filter(voice => voice.lang === 'pt-BR')[0];
    utterance.pitch = 1.5;
    utterance.rate = 1.25;
    utterance.volume = 10;
    this.speechSynthesis.speak(utterance);
    console.log('XXXX', texto);

  }
}

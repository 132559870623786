import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CidadeConvenioGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!localStorage.getItem('Cidade')) {
      this.router.navigate(['']);
      console.log('Escolher Cidade');
      return false;
    }

    // this.router.navigate(['ping']);
    console.log('2 - Escolher Cidade');
    return true;
  }

}

export const environment = {
  production: false,
  apiKey: "3e81b46d-0245-655f-4e7a-e79945ab6045",
  // apiURL: "https://api.ssita.com.br/demo/v1",
  // apiURL: "https://api.ssita.com.br/itaguai/v1",
  // apiURL: "https://api.casimirodeabreu.maplebr.com.br/v1",
  versionPainel: 6,
  firebase: {
    apiKey: "AIzaSyCeG-PGEkbusU2fgiMP1AmMC1r4DM_m02Q",
    authDomain: "sigs-79332.firebaseapp.com",
    projectId: "sigs-79332",
    storageBucket: "sigs-79332.appspot.com",
    messagingSenderId: "391093147975",
    appId: "1:391093147975:web:aebb16c42f965b7e889462"
    // appId: "1:391093147975:android:da0533781ecea9d8889462"
  }
};

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CidadeConvenioGuard } from './services/cidade-convenio.guard';

const routes: Routes = [
  {
    path: 'convenio',
    loadChildren: () => import('./convenio/convenio.module').then(m => m.ConvenioModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [CidadeConvenioGuard]
  },
  {
    path: 'ping',
    loadChildren: () => import('./ping/ping.module').then(m => m.PingModule),
    canActivate: [CidadeConvenioGuard]
  },
  {
    path: '',
    redirectTo: 'convenio',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {


  constructor(
    private platform: Platform,
  ) {

  }

  async ngOnInit() {

    // this.platform.ready().then(async () => {
    //   await SplashScreen.hide();
    //   // this.statusBar.hide();
    //   // this.autostart.enable();
    // });

  }

}
